import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "./components/Dashboard.vue";

const routes = [
   {
      path: "/",
      name: "home",
      component: () => import("./pages/Home.vue"),
      children: [
         {
            path: "/",
            name: "dashboard",
            component: Dashboard,
            meta: { requiresAuth: true },
         },
         {
            path: "/table",
            name: "table",
            component: () => import("./components/TableDemo.vue"),
            meta: { requiresAuth: true },
         },
         {
            path: "/empty",
            name: "empty",
            component: () => import("./components/EmptyPage.vue"),
            meta: { requiresAuth: true },
         },
         {
            path: "/specialist",
            name: "specialist",
            component: () => import("./pages/specialist/Index.vue"),
            meta: { requiresAuth: true },
         },
         {
            path: "/location",
            name: "location",
            component: () => import("./pages/location/Index.vue"),
            meta: { requiresAuth: true },
         },
         {
            path: "/doctortype",
            name: "doctortype",
            component: () => import("./pages/doctortype/Index.vue"),
            meta: { requiresAuth: true },
         },
         {
            path: "/doctor",
            name: "doctor",
            component: () => import("./pages/doctor/Index.vue"),
            meta: { requiresAuth: true },
         },
         {
            path: "/doctor/:id",
            name: "doctorDetail",
            component: () => import("./pages/doctor/Detail.vue"),
            meta: { requiresAuth: true },
         },
         {
            path: "/doctor/create",
            name: "doctorCreate",
            component: () => import("./pages/doctor/Create.vue"),
            meta: { requiresAuth: true },
         },
      ],
   },
   {
      path: "/login",
      name: "login",
      component: () => import("./pages/auth/Login.vue"),
      meta: { requiresVisitor: true },
   },
];

const router = createRouter({
   history: createWebHistory(),
   routes,
});

export default router;
