import { HTTP } from "../../service/http-common";

// initial state
const state = () => ({
   types: null,
   updatedData: null,
   deletedData: null,
   createdData: null,
});

// getters
const getters = {
   getAll: (state) => state.locations,
};

// actions
const actions = {
   fetchAllTypes({ commit }) {
      return new Promise((resolve, reject) => {
         HTTP.get("v1/doctortypes")
            .then((response) => {
               commit("setTypes", response.data.data);
               resolve(response.data.data);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   store({ commit }, type) {
      return new Promise((resolve, reject) => {
         HTTP.post("v1/doctortypes", type)
            .then((response) => {
               commit("saveType", response.data.data);
               resolve(response.data.data);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   update({ commit }, type) {
      return new Promise((resolve, reject) => {
         HTTP.put("v1/doctortypes/" + type.id, type)
            .then((response) => {
               commit("updateType", response.data.data);
               resolve(response.data.data);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   delete({ commit }, id) {
      return new Promise((resolve, reject) => {
         HTTP.delete("v1/doctortypes/" + id)
            .then((response) => {
               commit("deleteType", response.data);
               resolve(response.data);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
};

// mutations
const mutations = {
   setTypes: (state, types) => {
      state.types = types;
   },
   saveType: (state, type) => {
      state.createdData = type;
   },
   deleteType: (state, type) => {
      state.deletedData = type;
   },
   updateType: (state, type) => {
      state.updatedData = type;
   },
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
