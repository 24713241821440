import { HTTP } from "../../service/http-common";
// initial state
const state = () => ({
   is_auth: false,
   token: "",
});

// getters
const getters = {
   isAuth(state) {
      return state.is_auth;
   },
   getToken(state) {
      return state.token;
   },
};

// actions
const actions = {
   login({ commit }, input) {
      return new Promise((resolve, reject) => {
         const bodyParameter = {
            email: input.email,
            password: input.password,
         };

         HTTP.post("v1/login", bodyParameter)
            .then((response) => {
               commit("setToken", "Bearer " + response.data.data.access_token);
               commit("setAuth", true);
               resolve(response);
            })
            .catch((error) => {
               reject(error.response.data.errors);
            });
      });
   },
   logout({ commit }) {
      return new Promise((resolve, reject) => {
         HTTP.post("v1/logout", "")
            .then((response) => {
               commit("setToken", "");
               commit("setAuth", false);
               resolve(response);
            })
            .catch((error) => {
               reject(error.response.data.errors);
            });
      });
   },
};

// mutations
const mutations = {
   setToken(state, token) {
      state.token = token;
   },
   setAuth(state, payload) {
      state.is_auth = payload;
   },
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
