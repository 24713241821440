import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import specialist from "./modules/specialist";
import location from "./modules/location";
import doctortype from "./modules/doctortype";
import doctor from "./modules/doctor";

const store = createStore({
   modules: {
      auth,
      specialist,
      location,
      doctortype,
      doctor,
   },
   state() {
      return {};
   },
   getters: {},
   actions: {},
   mutations: {},
   plugins: [createPersistedState()],
});

export default store;
