import axios from "axios";
import store from "../store";

const baseUrl = process.env.VUE_APP_BASE_URL;
axios.defaults.baseURL = baseUrl;

axios.interceptors.request.use(
   function (config) {
      config.headers.common["Authorization"] = store.getters["auth/getToken"];
      config.headers.common["Content-Type"] = "application/json";
      return config;
   },
   function (error) {
      return Promise.reject(error);
   }
);

export const HTTP = axios;
