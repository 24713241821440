import { HTTP } from "../../service/http-common";

// initial state
const state = () => ({
   locations: null,
   updatedData: null,
   deletedData: null,
   createdData: null,
});

// getters
const getters = {
   getAll: (state) => state.locations,
};

// actions
const actions = {
   fetchParentLocations({ commit }) {
      return new Promise((resolve, reject) => {
         HTTP.get("v1/locations?parent=1")
            .then((response) => {
               commit("setLocations", response.data.data);
               resolve(response.data.data);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   fetchAllLocations({ commit }) {
      return new Promise((resolve, reject) => {
         HTTP.get("v1/locations")
            .then((response) => {
               commit("setLocations", response.data.data);
               resolve(response.data.data);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   update({ commit }, location) {
      return new Promise((resolve, reject) => {
         HTTP.put("v1/locations/" + location.id, location)
            .then((response) => {
               commit("updateLocation", response.data.data);
               resolve(response.data.data);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   delete({ commit }, id) {
      return new Promise((resolve, reject) => {
         HTTP.delete("v1/locations/" + id)
            .then((response) => {
               commit("deleteLocation", response.data);
               resolve(response.data);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
};

// mutations
const mutations = {
   setLocations: (state, locations) => {
      state.locations = locations;
   },
   updateLocation: (state, location) => {
      state.updatedData = location;
   },
   deleteLocation: (state, id) => {
      state.deletedData = id;
   },
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
