import { HTTP } from "../../service/http-common";

// initial state
const state = () => ({
   doctors: null,
   doctor: null,
   updatedData: null,
   deletedData: null,
   createdData: null,
});

// getters
const getters = {
   getAll: (state) => state.doctors,
};

// actions
const actions = {
   fetchAllDoctors({ commit }) {
      return new Promise((resolve, reject) => {
         HTTP.get("v1/doctors")
            .then((response) => {
               commit("setDoctors", response.data.data);
               resolve(response.data.data);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   show({ commit }, id) {
      return new Promise((resolve, reject) => {
         HTTP.get("v1/doctors/" + id)
            .then((response) => {
               commit("showDoctor", response.data.data);
               resolve(response.data.data);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },

   store({ commit }, doctor) {
      return new Promise((resolve, reject) => {
         HTTP.post("v1/doctors", doctor)
            .then((response) => {
               commit("storeDoctor", response.data.data);
               resolve(response.data.data);
               console.log(response.data);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },

   delete({ commit }, id) {
      return new Promise((resolve, reject) => {
         HTTP.delete("v1/doctors/" + id)
            .then((response) => {
               commit("deleteDoctor", response.data);
               resolve(response.data);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
};

// mutations
const mutations = {
   setDoctors: (state, doctors) => {
      state.doctors = doctors;
   },
   showDoctor: (state, doctor) => {
      state.doctor = doctor;
   },

   storeDoctor: (state, doctor) => {
      state.createdData = doctor;
   },

   deleteDoctor: (state, doctor) => {
      state.deletedData = doctor;
   },
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
